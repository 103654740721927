<template>
  <div class="renderizar-qrcode">
    <v-container>
      <div v-if="this.$store.state.qrcode.statusDataImgQRCode === 2" class="red--text">
        Erro ao gerar o QRCode ou link indisponível.
      </div>
      <div v-else>
        <div  style="width: 100%; text-align: center; display: flex; justify-content: center;">
          <div style="width: 240px">
            <v-img :src="getImg" max-width="240" max-height="240"/>
          </div>
        </div>
        <div class="d-flex justify-center flex-fill text-center">
          <div class="caption" style="font-size: 0.755rem; text-align: center;">
            <v-btn id="btn-link-qrcode" tag="a" plain tile style="font-size: 0.755rem; text-transform: lowercase;" :href="$route.query.url">
              {{ $route.query.url }}
              <v-icon size="12" class="ml-1">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ['url','img',"type"],
  mounted() {
    if(!this.$route.query.img && !this.$route.query.url) {
      this.$store.dispatch("qrcode/getImgQRCode", {
        type: this.$route.query.type,
        uid: this.$route.query.uid,
      })
          .then(qrcode => {
            this.$router.replace(`?img=${btoa(qrcode.imgBase64)}&url=${this.$store.getters.getURLBase + qrcode.uid}`);
          })
    }
  },
  computed: {
    getImg() {
      return 'data:image;base64,' + atob(this.$route.query.img || '');
    }
  }
}
</script>

<style lang="scss">
#btn-link-qrcode {
  white-space: normal;
  span {
    max-width: 100%;
  }
}
</style>
